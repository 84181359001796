<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-row>
        <el-col :span="8">
          <el-form-item label="问诊类型：">
            <el-input
              v-model.trim="formInline.consultationName"
              v-check-permission
              placeholder="请输入问诊类型"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" @click="queryData()"> 查询 </el-button>
            <el-button type="primary" @click="queryClear()"> 重置 </el-button>
            <el-button
              v-if="$checkPermission('ADDCONSULTTYPE', $route)"
              type="primary"
              @click="dataOperation(null, 0)"
            >
              新增
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        prop="consultationCode"
        label="问诊类型编码"
        align="center"
      />
      <el-table-column
        prop="consultationName"
        label="问诊类型名称"
        align="center"
      />
      <el-table-column prop="direction" label="说明" align="center" />
      <el-table-column prop="price" label="价格" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITCONSULTTYPE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      loading: false,
      formInline: {
        consultationName: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    // ...mapState('componentAPI', {
    //   merchantList: state => state.merchantList
    // }),
    // ...mapState('categoryManage', {
    //   categoryList: state => state.categoryList
    // })
  },
  mounted() {
    this.getConsultationList();
  },
  methods: {
    ...mapActions('basicDictionary', ['consultationTypePageQuery']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getConsultationList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getConsultationList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        consultationName: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getConsultationList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getConsultationList();
    },
    // 分页查询问诊类型列表
    getConsultationList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.consultationTypePageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.$router.push({
        path: '/basicDictionary/consultTypeDetail',
        query: {
          detail: item,
          type: Number(type),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
